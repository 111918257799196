@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "Pixelify Sans", sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400..700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
}

@font-face {
    font-family: "Dancing Script", cursive;
    src: url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pixelify+Sans&family=Raleway:wght@200&display=swap');
}
@font-face {
    font-family: "Ubuntu", sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
}
body {
    background-image: url("./assets/textura.jpg");
    font-family: "Ubuntu", sans-serif;
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: 0px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 15px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */

    margin-bottom: 20px;
}